<template>
  <div class="iot-LargeDataScreen-body">
    <div class="iot-item-left">
      <!-- 设备统计 -->
      <div class="iot-item-echarts">
        <el-carousel height="280px" :interval="5000">
      <el-carousel-item >
        <div class="item-title">{{ LargeDataScreenConfig.title1 }}</div>
        <div class="echarts-body">
          <div
            id="equipmentcharts"
            ref="equipmentcharts"
            :style="`width: 212px; height:194px;zoom:${zoom};transform:scale(${
              1 / zoom
            });transform-origin:0 0`"
          ></div>
          <div class="countArr">
            <ul>
              <li>
                <div style="background: rgb(114, 221, 116)"></div>
                正常：{{ countArr.normalCount }}
              </li>
              <li>
                <div style="background: rgb(245, 108, 108)"></div>
                告警：{{ countArr.alarmCount }}
              </li>
              <li>
                <div style="background: rgb(230, 162, 60)"></div>
                故障：{{ countArr.faultCount }}
              </li>
              <li>
                <div style="background: rgb(216, 216, 216)"></div>
                离线：{{ countArr.offLine }}
              </li>
            </ul>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item >
        <div class="item-title">{{ LargeDataScreenConfig.title4 }}</div>
        <div class="data-body">
          <div style="padding: 10px 20px; position: relative">
            <el-date-picker
              ref="picker"
              popper-class="el-date-style"
              size="mini"
              v-model="alarmInfDate"
              type="date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="选择日期"
              @change="getAlarmInfo"
            >
            </el-date-picker>
          </div>

          <div
            id="alarmInfo"
            ref="alarmInfo"
            :style="`width: 296px; height:175px;zoom:${zoom};transform:scale(${
              1 / zoom
            });transform-origin:0 0`"
          ></div>
        </div>
      </el-carousel-item>
    </el-carousel>
      
      </div>

      <!-- 组织列表 -->
      <div class="iot-item-list">
        <div class="item-title">{{ LargeDataScreenConfig.title2 }}</div>
        <el-input
          style="position: fixed;width: 300px;margin-bottom: 20px !important;"
  placeholder="输入关键字进行查找"
  v-model="filterText">
</el-input>
        <div class="list-body">
          <el-tree
            :filter-node-method="filterNode"
            ref="tree"
            :accordion="true"
            node-key="id"
            :highlight-current="true"
            style="background-color: transparent; color: #ccf2ff"
            :default-expanded-keys="[id]"
            :data="treeData"
            :props="props"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
      </div>
    </div>
    <div class="iot-item-center">
      <!-- 组态 -->
      <div class="iot-item-canvas">
        <div
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="canvas-title">
            <div
              style="z-index: 99; position: relative"
              :class="{ check: isCheck }"
              @click="
                isCheck = true;
                pShow = true;
                isMap=false
              "
            >
              设备组态
            </div>
            <div
              :class="{ check: !isCheck && !isMap }"
              @click="getCameraPro(),isMap=false"
              style="z-index: 99; position: relative"
            >
              视频监控
            </div>
            <div
              :class="{ check: !isCheck && isMap}"
              @click="getMap()"
              style="z-index: 99; position: relative"
            >
              地图全览
            </div>
          </div>
          <div class="iot-canvas-box" v-if="isCheck" id="HMIExecute" @dblclick="fullscreenChange" >
            <!-- <i
            class="el-icon-full-screen" 
            style="color:#fff;font-size: 20px;cursor: pointer;margin-left: 20px;" 
            @click="fullscreenChange"></i> -->
            <HMIExecute
               ref="HMIExecute"
              :class="{ filterCss: projectState == 2 }"
              v-if="pShow"
              :key="hmiKey"
              :canvasWidth="canvasWidth"
            />
          </div>
          <div
            class="iot-canvas-box camera"
            v-if="!isCheck && !isMap"
            v-loading="loading"
            element-loading-text="看板正在初始化，请耐心等待一会..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 1)"
          >
            <div class="camera-box">
              <div
                style="
                  width: 326px;
                  height: 193px;
                  margin: 0;
                  position: relative;
                "
                v-for="(item, index) in userList"
                :key="index"
                @click="monitorClick(item)"
              >
                <img
                  v-if="!item.none"
                  :src="item.picUrl"
                  :alt="item.picUrl"
                  style="width: 100%; color: #ccf2ff"
                />
                <i
                  v-else
                  class="el-icon-picture-outline"
                  style="
                    color: #c0c4cc;
                    font-size: 30px !important;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  "
                ></i>
              </div>
              <div class="prev" v-if="pageNum > 1" @click="prev">
                <svg
                  t="1640670879336"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2034"
                  width="40"
                  height="40"
                >
                  <path
                    d="M729.6 931.2l-416-425.6 416-416c9.6-9.6 9.6-25.6 0-35.2-9.6-9.6-25.6-9.6-35.2 0l-432 435.2c-9.6 9.6-9.6 25.6 0 35.2l432 441.6c9.6 9.6 25.6 9.6 35.2 0C739.2 956.8 739.2 940.8 729.6 931.2z"
                    p-id="2035"
                    fill="#ccf2ff"
                  ></path>
                </svg>
              </div>
              <div class="next" v-if="pageNum > 1" @click="next">
                <svg
                  t="1640670931863"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2181"
                  width="40"
                  height="40"
                >
                  <path
                    d="M761.6 489.6l-432-435.2c-9.6-9.6-25.6-9.6-35.2 0-9.6 9.6-9.6 25.6 0 35.2l416 416-416 425.6c-9.6 9.6-9.6 25.6 0 35.2s25.6 9.6 35.2 0l432-441.6C771.2 515.2 771.2 499.2 761.6 489.6z"
                    p-id="2182"
                    fill="#ccf2ff"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        <div
        class="iot-canvas-box "
            v-if="!isCheck && isMap"
            style="padding-left: 30px;"
        >
         <largeMap v-if="isMap" style="width: 960px;height: 500px;" :mapStyle="mapStyle"></largeMap>
         <div style="width: 100%;">
          <el-radio-group v-model="mapStyle" style="transform: translate(45%,5px);">
          <el-radio label="normal">标准</el-radio>
          <el-radio label="light">月光银</el-radio>
          <el-radio label="whitesmoke">远山黛</el-radio>
          <el-radio label="fresh">草色青</el-radio>
          <el-radio label="macaron">马卡龙</el-radio>
          <el-radio label="darkblue">极夜蓝</el-radio>
         </el-radio-group>
         </div>
        </div>
        </div>
      </div>
      <!-- 历史数据 -->
      <div class="iot-item-history">
        <div class="item-title">{{ LargeDataScreenConfig.title3 }}</div>
        <div class="select-form">
          <el-form :inline="true" :model="historyFrom" class="iot-form-inline">
            <!-- 项目名称 -->
            <el-form-item>
              <el-select
                size="mini"
                v-model="historyFrom.projectId"
                @change="getProject"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 参数类型 -->
            <el-form-item>
              <el-select
                @change="paramChange"
                multiple
                collapse-tags
                :multiple-limit="5"
                size="mini"
                v-model="historyFrom.paramId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in paramList"
                  :key="
                    item.paramId +
                    '/' +
                    item.gatewayId +
                    '/' +
                    item.subOriginalId
                  "
                  :label="item.paramName"
                  :value="
                    item.paramId +
                    '/' +
                    item.gatewayId +
                    '/' +
                    item.subOriginalId
                  "
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 日期 -->
            <el-form-item>
              <el-date-picker
                popper-class="el-date1-style"
                @change="paramChange"
                size="mini"
                v-model="historyFrom.date"
                type="date"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div
          id="history"
          ref="history"
          :style="`width: 1056px; height:174px;zoom:${zoom};transform:scale(${
            1 / zoom
          });transform-origin:0 0`"
        ></div>
      </div>
    </div>
    <div class="iot-item-right">
      <!-- 告警联系人 -->
      <div class="iot-item-warning">
        <div class="item-title">{{ LargeDataScreenConfig.title6 }}</div>
        <div class="warning-body">
          <div>
            项目状态:<span :style="project.style" class="iot-project-status">{{
              project.content
            }}</span>
            <div class="signalImg-box">
              <img
                class="signalImg"
                v-if="projectState != 2"
                :src="
                  constant.SYSTEM_IMG +
                  'menu' +
                  (signal > 20 ? '2' : signal > 15 ? '3' : '4') +
                  '.png'
                "
              />
              <img
                class="signalImg"
                v-else-if="projectState == 2"
                :src="constant.SYSTEM_IMG + 'menu6.png'"
              />
            </div>
            <div class="signalImg-box" v-for="item in proList" :key="item.ysId">
              <el-dropdown class="ysList">
                <img
                  style=""
                  :src="constant.SYSTEM_IMG + 'menu1.png'"
                  @click="monitorClick(item)"
                />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    >摄像头名称：{{ item.ysName }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div>项目名称： {{ projectName }}</div>
          <div>
            联系人：
            <span
              v-for="(item, index) in projectGroupDetail.contacts"
              :key="index"
            >
              {{ item.contactsName }}
            </span>
          </div>
          <div>备注： {{ projectGroupDetail.remark }}</div>
          <div>地址： {{ projectGroupDetail.projectGroupAddr }}</div>
        </div>
      </div>
      <!-- 实时数据 -->
      <div class="iot-item-alarm newalarm">
        <div class="item-title">{{ LargeDataScreenConfig.title5 }}</div>
        <div class="alarm-body">
          <div class="alarm-tips" v-if="warningList.length == 0">暂无告警</div>
          <ul
            v-else
            ref="ul"
            :style="{ marginTop: top }"
            @mouseenter="stop()"
            @mouseleave="up()"
          >
            <li v-for="(item, index) in warningList" :key="index">
              <div>设备名称：{{ item.projectName }}</div>
              <div>所属项目：{{ item.projectGroupName }}</div>
              <div>告警内容：{{ item.alarmInfo }}</div>
              <div>告警时间：{{ item.startTime }}</div>
              <div style="color: red; position: relative">
                当前状态：{{ item.alarmState }}
                <div
                  @click="warningClick(item.projectId, item.planId, item)"
                  class="openBtn"
                >
                  点击查看
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 0;
                  border: 1px solid rgb(8, 80, 132);
                "
              ></div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 实时报警 -->
      <!-- <div class="iot-item-data">
        <div class="item-title">{{ LargeDataScreenConfig.title4 }}</div>
        <div class="data-body">
          <div style="padding: 10px 20px; position: relative">
            <el-date-picker
              ref="picker"
              popper-class="el-date-style"
              size="mini"
              v-model="alarmInfDate"
              type="date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="选择日期"
              @change="getAlarmInfo"
            >
            </el-date-picker>
          </div>

          <div
            id="alarmInfo"
            ref="alarmInfo"
            :style="`width: 296px; height:175px;zoom:${zoom};transform:scale(${
              1 / zoom
            });transform-origin:0 0`"
          ></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import HMIExecute from "../view/HMIExecute.vue";
import largeMap from '../view/map.vue'
import * as echarts from "echarts/core";
import { visitUrl } from "@/util/global.js";
import { PLAN_IMG, OSS_URL, SYSTEM_IMG } from "../util/constant";
let that;
export default {
  name: "LargeDataBody",

  components: {
    HMIExecute,
    largeMap,
  },
  data: function () {
    return {
      tiemr:null,
      defaultProps: {
          children: 'children',
          label: 'label'
        },
      filterText: '',
      id: 0,
      pId:0,
      isscreenfull:false,
      mapStyle:'normal',
      isMap:true,
      timer: null,
      newArrList: [],
      options: [],
      props1: {
        lazy: true,
        checkStrictly: true,
        emitPath: false,
        lazyLoad(node, resolve) {
          that.getChildProjectGroupTree(node.value);
          return that.getlazyLoad(node, resolve);
        },
      },
      zoom: 1,
      projectName: "",
      getWarningVisit: false,
      signal: "",
      constant: { PLAN_IMG, OSS_URL, SYSTEM_IMG },
      project: {},
      projectState: "",
      LargeDataScreenConfig: {},
      cameraVis: false,
      itv: null,
      alarmSetInterval: null,
      activeIndex: 0,
      loading: false,
      proList: [],
      setTimeout: null,
      proFrom: {
        projectId: 0,
        current: 1,
        size: 6,
      },
      userListFrom: {
        projectId: 0,
        current: 1,
        size: 6,
      },
      userList: null,
      pageNum: 0,
      // 摄像头列表
      cameraList: [],
      alarmInfDate: "",
      isCheck: false,
      paramList: {},
      //当前项目列表
      projectList: {},
      historyFrom: {
        projectId: "",
        paramId: [],
        date: "",
      },
      projectGroupDetail: {},
      checkedKeys: [],
      pShow: false,
      hmiKey: "",
      canvasWidth: "960px",
      props: {},
      //树列表数据
      treeData: [],
      selectUserId: "",
      projectGroupChangeArr: [],
      //告警分页
      recordForm: {
        current: 1,
        size: 10,
        alarmType: "",
      },
      num: 0,
      //告警数据
      warningList: [],
      warningList1: [],
      //设备数据
      countArr: {},
      //设置时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      // 设备统计饼状图
      getData(data, colorArr, ref) {
        let option = {
          tooltip: {
            trigger: "item",
          },
          plotOptions: {
            pie: {
              colors: colorArr,
            },
          },

          series: [
            {
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },

              emphasis: {
                label: {
                  show: true,
                  fontSize: "20",
                  fontWeight: "bold",
                  color: "rgb(190,242,246)",
                },
              },
              labelLine: {
                show: false,
              },
              data: data,
            },
          ],
        };
        var chartDom = this.$refs[ref];

        let myChart = echarts.init(chartDom);

        myChart.setOption(option);
        // this.$highcharts.chart(this.$refs[ref], option);
      },
      getHistory(data, date, ref) {
        data.forEach((item) => {
          if (item.data.length > 1) {
            let arrData = [];
            item.data.forEach((ite) => {
              let newArr = [];
              for (let key in ite) {
                newArr.push(key, ite[key]);
              }
              arrData.push(newArr);
            });
            this.$set(item, "data", arrData);
          }
        });
        let option = {
          tooltip: {
            trigger: "axis",
            position: function (pt) {
              return [pt[0], "10%"];
            },
          },
          grid: {
            y: 10,
            y2: 30,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: date,
          },
          yAxis: {
            type: "value",
            nameLocation: "end",
            boundaryGap: [0, "100%"],
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          series: data,
        };
        var chartDom = this.$refs[ref];
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
        // this.$highcharts.chart(this.$refs[ref], option);
      },
      getAlarm(data, date, ref) {
        let option = {
          grid: {
            y: 0,
            y2: 0,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: date,
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              splitLine: { show: false },
            },
          ],
          series: [
            {
              type: "bar",
              barWidth: "80%",
              label: {
                show: true,
                position: "top",
                color: "#ccf2ff",
              },
              data: data,
            },
          ],
        };
        var chartDom = this.$refs[ref];
        let myChart = echarts.init(chartDom);
        myChart.setOption(option);
        // this.$highcharts.chart(this.$refs[ref], option);
      },
    };
  },
  watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
  computed: {
    top() {
      if (
        that.activeIndex * 2.34 + 8.3 > 7.2 * that.warningList.length &&
        that.warningList.length >= 2
      ) {
        if (this.num >= 2) {
          this.num = 0;
          that.activeIndex = 1;

          that.stop();
          that.alarmSetInterval = null;
          that.$refs["ul"].style.transition = "none";
          that.recordForm.current = 1;
          setTimeout(() => {
            that.$refs["ul"].style.transition = "all 1.5s linear";
            that.up();
            that.activeIndex++;
          }, 166);
        }
        if (that.warningList.length > 1) {
          if (that.getWarningVisit) {
            that.recordForm.current++;
            that.getWarningList();
          } else {
            this.num++;
            let arr = [...that.warningList, ...that.warningList1];
            that.warningList = arr;
          }
        }
      }
      return -that.activeIndex * 40 + "px";
    },
  },
  methods: {
    filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
    fullscreenChange() {
      let dom = document.getElementById('HMIExecute')
      screenfull.toggle(dom)
    },
    getlazyLoad(node, resolve) {
      const { level } = node;
      let type = level == 0 ? that.$store.state.userId : node.value;
      const nodes = [];
      // if (level == 1 && type == that.$store.state.userId) {
      //   node.loading = false
      //   node.loaded=true
      //   return
      // }
      that.$api.projectGroup.searchUserChild(type).then((res) => {
        if (res.code == 200) {
          node.loading = false;
          node.loaded = true;
          if (res.data.length > 0) {
            res.data.map((item) => {
              let obj = {
                value: item.userId,
                label: item.fullName,
                leaf: !item.children,
              };
              nodes.push(obj);
            });
            if (level == 0) {
              nodes.unshift({
                value: that.$store.state.userId,
                label: that.$store.state.name,
                leaf: true,
              });
              that.options = nodes;
            }
          }
          resolve(nodes);
        }
      });
    },
    //回显
    async getChildren(userId) {
      await that.$api.account.getAccount(userId).then((res) => {
        if (res.code == 200) {
          let obj = {
            label: res.data.fullName,
            value: res.data.userId,
          };
          this.newArrList.push(obj);
          if (
            res.data.parentId &&
            res.data.parentId != that.$store.state.userId
          ) {
            that.getChildren(res.data.parentId);
          } else {
            let children = [];
            this.newArrList.forEach((item, index) => {
              if (index == 0) {
                children = [{ ...item }];
              } else {
                children = [{ ...this.newArrList[index], children: children }];
              }
            });
            this.options.push(children[0]);
          }
        }
        return;
      });
    },
    //告警信息点击跳转
    async warningClick(projectId, planId, item) {
      that.projectName = item.projectName;
      clearInterval(that.itv);
      await that.getProjectGroup(item.projectGroupId);
      that.selectUserId = that.projectGroupDetail.createId;
      await that.getChildProjectGroupTree(that.selectUserId);
      this.$refs.tree.setCheckedKeys([projectId]);
      this.id=item.projectGroupId
      this.openProject(projectId, planId);
      that.historyFrom.planId = planId;
      that.proFrom.projectId = projectId;
      that.historyFrom.projectId = projectId;
      that.getProject();
      that.getAlarmInfo();
      that.cameraVis = false;
      that.getProList();
    },
    //获取数据大屏配置
    getDataScreenConfig() {
      this.$api.SetTitleConfig.getDataScreenConfig().then((res) => {
        this.LargeDataScreenConfig = res.data;
      });
    },
    stop() {
      clearInterval(that.alarmSetInterval);
      that.alarmSetInterval = null;
    },
    up() {
      that.stop();
      if (that.warningList.length >= 2) {
        that.alarmSetInterval = setInterval(() => {
          that.activeIndex++;
        }, 1500);
      }
    },

    monitorClick: function (obj) {
      if (!obj.none) {
        window.open(
          visitUrl +
            "#/openCamera?deviceSerial=" +
            obj.deviceSerial +
            "&validateCode=" +
            obj.validateCode +
            "&ysId=" +
            obj.ysId +
            "&channelNo=" +
            obj.channelNo
        );
      } else {
        this.$message({
          type: "warning",
          message: "暂无此摄像头",
        });
      }
    },

    getCameraPro() {
      if (that.isCheck) {
        that.pShow = false;
        that.isCheck = false;
        that.loading = true;
        // clearInterval(that.itv);
        //  that.itv = null;
        that.getUserList();
      }
    },
    getMap() {
        that.pShow = false;
        that.isCheck = false;
        that.isMap=true
   },
    // 获取视频监控图片列表
    getProList() {
      that.$api.largeDataScreen.getProList(that.proFrom).then((res) => {
        if (res.code == 200) {
          that.proList = res.data.records;
        }
      });
    },
    next() {
      clearInterval(that.setTimeout);
      that.setTimeout = null;
      that.userListFrom.current++;
      if (that.userListFrom.current > that.pageNum) {
        that.userListFrom.current = 1;
      }
      that.getUserList();
    },
    prev() {
      clearInterval(that.setTimeout);
      that.setTimeout = null;
      that.userListFrom.current--;
      if (that.userListFrom.current < that.pageNum) {
        that.userListFrom.current = that.pageNum;
      }
      that.getUserList();
    },
    // 获取视频监控图片列表u
    getUserList() {
      that.$api.largeDataScreen.getProList(that.userListFrom).then((res) => {
        if (res.code == 200) {
          let arr = [];
          for (let i = 0; i < 6; i++) {
            if (res.data.records[i]) {
              arr.push(res.data.records[i]);
            } else {
              arr.push({
                none: true,
              });
            }
          }
          that.userList = arr;
          that.loading = false;
          that.pageNum = Math.ceil(res.data.total / res.data.size);
          clearInterval(that.setTimeout);
          that.setTimeout = null;
          that.setTimeout = setInterval(() => {
            if (that.pageNum > 1) {
              that.userListFrom.current++;
              if (that.userListFrom.current > that.pageNum) {
                that.userListFrom.current = 1;
              }
            }
            that.getUserList();
          }, 300000);
        }
      });
    },

    //获取柱状图数据
    getAlarmInfo() {
      that.$api.largeDataScreen.getAlarmInfo(that.alarmInfDate).then((res) => {
        if (res.code == 200) {
          let data = [];
          let date = [];
          res.data.forEach((item) => {
            data.push(item.result);
            date.push(item.startDate);
          });
          this.$nextTick(() => {
            that.getAlarm(data, date, "alarmInfo");
          })
        }
      });
    },
    getProject() {
      that.projectList.forEach((item) => {
        if (item.id == that.historyFrom.projectId) {
          that.getParamList(item.planId);
        }
      });
    },
    paramChange() {
      if (that.historyFrom.paramId.length > 0) {
        let subOriginalId = "";
        let paramIds = [];
        that.paramList.forEach((item) => {
          that.historyFrom.paramId.forEach((ite) => {
            if (
              item.paramId == ite.split("/")[0] &&
              item.gatewayId == ite.split("/")[1] &&
              item.subOriginalId == ite.split("/")[2]
            ) {
              subOriginalId = item.subOriginalId;
              paramIds.push(item.paramId);
            }
          });
        });
        let data = {
          projectId: that.historyFrom.projectId,
          date: that.historyFrom.date,
          ctrls: [
            {
              ctrlId: 0,
              subOriginalId,
              paramIds
            },
          ],
        };

        that.getHistoryData(data);
      }
    },
    //获取历史曲线数据
    getHistoryData(data) {
      that.$api.largeDataScreen.getHistoryData(data).then((res) => {
        if (res.code == 200) {
          let arr = [];
          res.data[0].data.forEach((element) => {
            arr.push({
              name: element.name,
              type: "line",
              smooth: true,
              symbol: "none",
              // areaStyle: {},
              data: element.data,
            });
          });

          that.getHistory(arr, res.data[0].date, "history");
        }
      });
    },
    //非布尔参数列表
    getParamList(id) {
      that.$api.parameter.getParamList(id).then((res) => {
        if (res.code == 200) {
          that.paramList = res.data;
          if (that.paramList.length > 0) {
            that.historyFrom.paramId = [
              that.paramList[0].paramId +
                "/" +
                that.paramList[0].gatewayId +
                "/" +
                that.paramList[0].subOriginalId,
            ];
            that.paramChange();
          }
        }
      });
    },
    // 获取告警联系人
    async getProjectGroup(id) {
      await that.$api.projectGroup.getProjectGroup(id).then((response) => {
        if (response.code == 200) {
          that.projectGroupDetail = response.data;
        }
        return;
      });
    },
    //打开组态
    openProject: function (projectId, planId) {
      that.pShow = false;
      this.$store.commit("setProjectId", projectId);
      this.$store.commit("setPId", planId);
      this.$store.commit("setPlatType", "pc");
      // that.simform = [];

      that.getProjectDetail(projectId);
    },

    getProjectDetail: function (projectId) {
      that.$api.projectGroup.getProject(projectId).then((response) => {
        if (200 == response.code) {
          that.projectDetail = response.data;
          that.getStatus(that.projectDetail.projectState);
          that.currentPName = that.projectDetail.projectName;
          that.hmiKey = new Date().getTime();
          // for (let i = 0; i < response.data.dtus.length; i++) {
          //   let obj = { left: "**", cardStatus: "**" };
          //   that.simform.push(obj);
          // }
          that.openV(projectId);
          that.getV(projectId);
          that.pShow = true;
          clearInterval(that.itv);
          that.itv = setInterval(function () {
            that.getV(projectId);
          }, 5000);
        }
      });
    },
    getV: function (projectId) {
      that.$api.projectGroup.checkV(projectId).then((response) => {
        if (200 == response.code) {
          that.vStatus = response.data.fastState;
          that.projectState = response.data.projectState;
          that.signal = response.data.signal;
          that.getStatus(response.data.projectState);
        }
      });
    },
    getStatus: function (projectState) {
      let obj = {
        style: null,
        content: null,
      };
      switch (projectState) {
        case 1:
          obj = {
            style: { "background-color": "#1eaa39" },
            content: "在线",
          };
          break;
        case 2:
          obj = {
            style: { "background-color": "rgba(180,180,181)" },
            content: "离线",
          };
          break;
        case 3:
          obj = {
            style: { "background-color": "#e50012" },
            content: "报警",
          };
          break;
        case 4:
          obj = {
            style: { "background-color": "#f29500" },
            content: "故障",
          };
          break;
        default:
          break;
      }

      that.project = obj;
    },
    openV: function (projectId) {
      that.$api.projectGroup.openV(projectId).then((response) => {
        if (200 == response.code) {
          // that.vStatus = response.data;
          that.getV(projectId);
        }
      });
    },
    //树状结构点击事件
    handleNodeClick: function (data, node) {
      
      if (data.planId) {
        this.$api.projectGroup.getProjectNoPage(node.parent.data.id).then((res) => {
          if (res.code == 200) {
            that.projectList = res.data
            that.getParamList(data.planId)
          }
        })
        this.pId=data.id
        that.projectName = data.label;
        clearInterval(that.itv);
        that.getProjectGroup(node.parent.data.id);
        this.$refs.tree.setCheckedKeys([data.id]);
        this.openProject(data.id, data.planId);
        that.historyFrom.planId = data.planId;
        that.proFrom.projectId = data.id;
        that.historyFrom.projectId = data.id;
        that.getProject();
        that.getAlarmInfo();
        that.cameraVis = false;
        that.getProList();
        // that.treeData.forEach((item) => {
        //   if (item.id == node.parent.data.id) {
        //     that.projectList = item.children;
        //   }
        // });
      } else {
        that.getProjectGroup(data.id);
        that.getProjectNoPage(data)
      }
    },
     // 根据项目组获取项目列表不分页
     getProjectNoPage(data) {
      this.id = data.id
      // this.pId=null
      this.$api.projectGroup.getProjectNoPage(data.id).then((res) => {
        that.projectName=''
        if (res.code == 200 && res.data.length) {
          let obj = []
          res.data.forEach((item) => {
            obj.push({id: item.projectId,label:item.projectName,planId:item.planId})
          })
      
          data.children = obj
          that.projectList = res.data
          that.projectName = res.data[0].projectName;
          that.historyFrom.projectId = res.data[0].projectId;
          // this.pId = res.data[0].projectId
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys([res.data[0].projectId]);
         })
          this.getProjectGroup(res.data[0].projectGroupId);
          that.proFrom.projectId = res.data[0].projectId;
          this.openProject(
            res.data[0].projectId,
            res.data[0].planId
          );
          that.getParamList(res.data[0].planId);
        } else {
          this.$message('该项目组下无设备')
        }
        that.cameraVis = false;
              that.getProList();
      })
    },
      //获取项目组列表 
      getProjectGroupList() {
      this.$api.warning.getProjectGroup().then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
          this.treeData.push({id:item.projectGroupId,label:item.projectGroupName,children:''})
          })
         that.getChildProjectGroupTree()
        }
      })
    },
    //   获取用户列表
    getChildProjectGroupNoPage: function () {
      that.selectUserId = that.$store.state.userId;
      that.getChildProjectGroupTree(that.$store.state.userId);
    },
    // 获取项目树
    async getChildProjectGroupTree(data) {
      let from = data ? data : that.$store.state.userId
      await that.$api.projectGroup
        .getChildProjectGroupTree(from)
        .then((response) => {
          if (200 == response.code) {
            this.getProjectGroup(response.data[0].projectGroupId);
            this.id=response.data[0].projectGroupId
            that.pShow = false;
            that.treeData.forEach((item1,index) => {
              if (item1.id == response.data[0].projectGroupId && !response.data[0]?.children.length) {
                that.treeData.splice(index, 1);
                  that.treeData.unshift({id:response.data[0].projectGroupId,label: response.data[0].projectGroupName})
              }
            })
            if (response.data[0]?.children.length) {
              that.treeData.forEach((item,index) => {
                if (item.id == response.data[0].projectGroupId) {
                  let obj = []
                  response.data[0].children.forEach((it) => {
                    obj.push({id:it.projectId,label:it.projectName,planId:it.planId})
                  })
                this.pId=obj[0].id
                  item.children = obj
                  const newItem = { ...item }
                  that.treeData.splice(index, 1);
                  that.treeData.unshift(newItem)
                }
              })
              that.projectList = response.data[0].children;
              that.historyFrom.projectId = "";
              that.historyFrom.paramId = [];
              if (response.data[0].children.length > 0) {
                that.historyFrom.projectId =
                  response.data[0].children[0].projectId;
                that.proFrom.projectId = response.data[0].children[0].projectId;
                this.$refs.tree.setCheckedKeys([
                  response.data[0].children[0].projectId,
                ]);
                that.projectName = response.data[0].children[0].projectName;
                this.openProject(
                  response.data[0].children[0].projectId,
                  response.data[0].children[0].planId
                );
                that.getParamList(response.data[0].children[0].planId);
              }
              that.cameraVis = false;
              that.getProList();
            }
          }
          return;
        });
    },
    //获取告警信息
    getWarningList() {
      that.$api.home.getWarningPage(that.recordForm).then((response) => {
        if (this.timer) clearTimeout(this.timer);
    this.timer = null;
    this.timer = setTimeout(() => {
      that.getWarningList();
    }, 10000);
        if (response.code == 200) {
          if (that.recordForm.current == 1) {
            that.stop();
            that.warningList = response.data.records;
            that.warningList1 = response.data.records;
          } else if (that.recordForm.current > 1) {
            let arr = [...that.warningList, ...response.data.records];
            that.warningList = arr;
            that.warningList1 = JSON.parse(JSON.stringify(that.warningList));
          }
          if (response.data.records.length == that.recordForm.size) {
            that.getWarningVisit = true;
          } else {
            that.getWarningVisit = false;
          }
          if (that.warningList.length >= 2) {
            that.up();
          }
        }
      });
    },
    //获取设备数据
    getCount: function () {
      that.$api.home.getCount().then((response) => {
        if (response.code == 200) {
          that.countArr = response.data;
          that.getData(
            [
              {
                value: that.countArr.normalCount,
                name: "正常",
                itemStyle: {
                  color: "rgb(114,221,116)",
                },
              },
              {
                value: that.countArr.alarmCount,
                name: "告警",
                itemStyle: {
                  color: "rgb(245,108,108)",
                },
              },
              {
                value: that.countArr.faultCount,
                name: "故障",
                itemStyle: {
                  color: "rgb(230,162,60)",
                },
              },
              {
                value: that.countArr.offLine,
                name: "离线",
                itemStyle: {
                  color: "rgb(216,216,216)",
                },
              },
            ],
            [],
            "equipmentcharts"
          );
        }
      });
    },
  },
  created() {
    that = this;
    // this.canvasWidth = window.screen.width/1920*960/(1/window.devicePixelRatio) + "px";
    this.getDataScreenConfig();
    screenfull.on('change', () => {
      this.isscreenfull = screenfull.isFullscreen
      console.log(this.isscreenfull);
      if (!this.isscreenfull) {
        this.canvasWidth = '960px'
      } else {
        this.canvasWidth = '1920px'
      }
      that.pShow=false
      setTimeout(() => {
        that.pShow=true
      }, 100)
    })
  },
  mounted() {
    this.zoom = 1 / document.body.style.zoom;
    window.addEventListener("resize", () => {
      this.zoom = 1 / document.body.style.zoom;
    });
    that.getWarningList();
    let a = document.body;
    a.className = "LargeDataScreen";
    // 获取设备数据
    this.getCount();
    that.getProjectGroupList()
    let date = new Date();
    let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    let dates = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    that.historyFrom.date = date.getFullYear() + "-" + month + "-" + dates;
    that.alarmInfDate = date.getFullYear() + "-" + month + "-" + dates;
    that.getAlarmInfo();
  },
  beforeDestroy() {
    let a = document.body;
    a.className = "";
    that.stop();
    clearInterval(that.setTimeout);
    that.setTimeout = null;
    clearInterval(that.itv);
    that.itv = null;
    clearInterval(that.timer);
    that.timer = null;
  },
};
</script>

<style scoped>
* {
  font-size: 13px !important;
}
.iot-LargeDataScreen-body {
  width: 1920px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  height: 93%;
  padding: 4px;
  box-sizing: border-box;
  font-family: "PingFang SC";
  /* background-color: rgba(66, 178, 22, 0.8) */
  background: url("../../public/backImg/bg.jpg");
}
.iot-LargeDataScreen-body > div {
  height: 100%;
  box-sizing: border-box;
  margin: 0 0.19px;
}
.iot-LargeDataScreen-body > div > div {
  box-sizing: border-box;
}
.iot-item-left {
  width: 394px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px 0;
}
.iot-item-center {
  width: 1114px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px 10px;
}
.iot-item-right {
  width: 364px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px 10px;
}
.iot-item-right > div {
  width: 100%;
  height: 289px;
  padding: 24px;
  box-sizing: border-box;
}
.newalarm{
  height: 589px !important;
}
.item-title {
  color: #ccf2ff;
  font-size: 18px !important;
  font-weight: bolder;
  padding: 2px;
}
.iot-item-echarts {
  height: 35%;
  padding: 19px;
  background: url("../../public/backImg/echarts.png");
  background-size: 100% 100%;
}
.echarts-body {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.iot-item-echarts ul {
  height: 150px;
  list-style: none;
  color: #ccf2ff !important;
}
.iot-item-echarts ul li {
  margin: 4px 0;
}
.iot-item-list {
  width: 100%;
  height: 62.5%;
  padding: 17px;
  background: url("../../public/backImg/list.png");
  background-size: 100% 100%;
}
#equipmentcharts {
  width: 212px;
  height: 194px;
}
.iot-item-canvas {
  width: 100%;
  height: 65.7%;
  background: url("../../public/backImg/canvas.png");
  background-size: 100% 100%;
  padding: 5px 0 0;
  position: relative;
}
.iot-canvas-box {
  width: 92%;
  padding: 3px 5px;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iot-item-history {
  box-sizing: border-box;
  width: 100%;
  height: 33%;
  padding: 19px 19px 0;
  background: url("../../public/backImg/history.png");
  background-size: 100% 100%;
}
#history {
  height: 174px;
}

.iot-item-warning {
  color: #ccf2ff;
  background: url("../../public/backImg/right.png");
  background-size: 100% 100%;
}
.warning-body {
  padding: 0.1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.warning-body > div {
  margin: 5px 0;
}
.iot-item-warning > div {
  margin: 5px 0;
}
.iot-item-data {
  background: url("../../public/backImg/right.png");
  background-size: 100% 100%;
}
.iot-item-alarm {
  padding: 0px;
  background: url("../../public/backImg/list.png");
  background-size: 100% 100%;
}
.iot-item-alarm .alarm-body {
  margin-top: 10px;
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  color: #ccf2ff;
}
.alarm-tips {
  width: 100%;
  font-size: 20px !important;
  color: #ccf2ff;
  text-align: center;
}
.alarm-body::-webkit-scrollbar {
  display: none;
  width: 10px;
}
.alarm-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aaaaaacc;
}
.iot-item-alarm ul {
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all 1.5s linear;
}
.iot-item-alarm ul li {
  margin: 5px 5px;
}
.iot-item-alarm ul li div {
  font-size: 13px;
  margin: 10px 0;
}
.list-body {
  height: 85%;
  margin-top: 40px;
  overflow-y: auto;
}
.list-body ::-webkit-scrollbar {
  width: 10px;
  /*对垂直流动条有效*/
  height: 10px;
  /*对水平流动条有效*/
}

/*定义滑块颜色、内阴影及圆角*/
.list-body ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aaaaaacc;
}
.select-form {
  padding: 5px 10px;
}
::v-deep .el-tree-node__content {
  background: transparent !important;
}
::v-deep .el-form-item {
  margin: 0 10px;
}
::v-deep .el-input__inner {
  /* width: 10vw;
  height: 3vh; */
  font-size: 12px;
  background-color: transparent;
  color: #ccf2ff;
}
.list-body ::v-deep .el-input__inner {
  /* width: 100%;
  height: 4vh; */
  background-color: transparent;
  color: #ccf2ff;
}
.canvas-title {
  /* display: flex; */
  width: 30px;
  height: 100%;
  display: inline-block;
  color: #ccf2ff;
  box-sizing: border-box;
  padding: 19px 0;
  margin-left: 19px;
  /* padding: 0 80px */
}
.canvas-title div {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  /* line-height: 30px; */
  border: 1px solid rgb(33, 84, 139);
  text-align: center;
  border-radius: 10px;
  margin: 10px 0;
  cursor: pointer;
}
.check {
  background: rgb(6, 67, 134);
}
.countArr ul {
  padding-top: 20px;
}
.countArr li {
  display: flex;
  font-size: 13px;
  text-align: left;
  line-height: 20px;
}
.countArr li div {
  width: 35px;
  height: 20px;
  border-radius: 5px;
  margin: auto 10px;
  margin-left: 0;
}
#alarmInfo {
  width: 100%;
  height: 175px;
}
/* ::v-deep .el-tag--info{
  color: rgb(190,242,246);
} */
.camera {
  height: 540px;
}
.camera-box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.camera-box:hover .next {
  display: block;
}
.camera-box:hover .prev {
  display: block;
}
.next {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 59px;
  height: 59px;
  line-height: 59px;
  font-size: 50px;
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  color: #ccf2ff;
  border-radius: 50%;
}
.prev {
  display: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 59px;
  height: 59px;
  line-height: 63px;
  font-size: 50px;
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  color: #ccf2ff;
  border-radius: 50%;
}

::v-deep .is-checked {
  color: #409eff !important;
}
::v-deep .iot-item-history .el-tag {
  background-color: transparent !important;
  color: #ccf2ff;
}
::v-deep .el-tag__close {
  background-color: transparent !important;
  color: #ccf2ff;
  border: 1px solid #ccf2ff;
}
.canvas-state {
  margin: 0 10px 0 0;
  padding: 50px 0;
}
.iot-project-status {
  display: inline-block;
  width: 50px;
  height: 19px;
  line-height: 19px;
  border-radius: 10px;
  text-align: center;
  font-size: 13px;
  color: #ffffff;
  box-sizing: border-box;
  margin-left: 10px;
  cursor: pointer;
}
.signalImg-box {
  display: inline-block;
  vertical-align: middle;
  height: 19px;
  width: 30px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  margin-left: 10px;
}
.signalImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-48%, -46%);
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}
.ysList {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}
.ysList img {
  width: 100%;
}
.filterCss {
  filter: grayscale(90%);
}
::v-deep .el-loading-mask {
  width: 100%;
  height: 100%;
}
::v-deep .el-tag {
  line-height: 20px !important ;
  height: 20px !important;
  font-size: 19px;
}
::v-deep .el-tag span {
  font-size: 12px;
}
::v-deep .el-tree-node__content {
  height: 25px;
  font-size: 19px;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tag__close {
  line-height: 16px;
  width: 16px;
  height: 16px;
  font-size: 14px;
}
.openBtn {
  position: absolute;
  right: 15px;
  top: 0;
  margin: 0 !important;
  color: #ccf2ff;
  border: 1px solid #ccf2ff;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
